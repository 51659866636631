:root {
    --primary-color: #70c7ba;
    --secondary-primary-color: #231f20;
    --tertiary-primary-color: #b6b6b6;
    --secondary-color: #49eacb;

    --header-font: 'Rubik', sans-serif;
    --sub-header-font: 'Oswald', sans-serif;
    --body-font: 'Lato', sans-serif;

    /* Dark mode colors */
    --dark-bg-color: #121212;
    --dark-text-color: #e0e0e0;
    --dark-primary-color: #90ccc0;
    --dark-secondary-color: #6aeedd;
    --dark-tertiary-color: #808080;
    --dark-card-bg: #1e1e1e;
    --dark-border-color: #333;
    --dark-active-bg: #2c2c2c;
    --dark-header-bg: #2a2a2a;
    --dark-stripe-bg: #252525;
    --dark-progress-bg: #333;
    --dark-progress-fill: #4a4a4a;
    --dark-link-color: #90ccc0;
    --dark-link-hover-color: #6aeedd;
    --dark-muted-color: #a0a0a0;
    --dark-badge-bg: #333;
    --dark-badge-text: #e0e0e0;
    --dark-badge-fair-mint-bg: #1e5245;
    --dark-badge-fair-mint-text: #e0e0e0;
    --dark-badge-pre-mint-bg: #5c4a1e;
    --dark-badge-pre-mint-text: #e0e0e0;
    --dark-sidebar-bg: #1a1a1a;
    --dark-sidebar-text: #e0e0e0;
    --dark-nav-hover-bg: #2c2c2c;
    --dark-nav-active-bg: #333;
    --dark-nav-active-text: #90ccc0;
    --dark-collapse-btn-bg: #333;
    --dark-collapse-btn-text: #e0e0e0;
    --dark-collapse-btn-hover-bg: #444;
}

html,
body {
    height: 100% !important;
    margin: 0;
    font-family: var(--body-font);
    color: var(--secondary-primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--header-font);
}

.sub-header {
    font-family: var(--sub-header-font);
}

.nav-tabs {
    overflow: hidden !important;
}
