body,
html,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f7fc;
}

/* Scrollbar disabling for all elements */
body * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
* ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

.App-header {
    text-align: center;
    padding: 20px;
    background-color: #282c34;
    color: white;
}

.App {
    position: relative;
    min-height: 100vh;
    display: flex;
    height: 100vh;
    flex-direction: row;
}

.sidebar {
    flex-shrink: 0;
    width: 250px;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: 60px;
}

.main-content-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.content {
    flex: 1;
    overflow: hidden;
    background-color: #f4f7fc;
}

@media (max-width: 991px) {
    .App {
        flex-direction: column;
    }

    .main-content-wrapper {
        padding: 0;
    }

    .main-content {
        width: 100%;
    }

    .sidebar {
        width: 100%;
    }

    .dark-mode-toggle {
        position: static;
        padding: 0;
    }
}

/* Dark mode styles */
.dark-mode {
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);
}

.dark-mode .App-header {
    background-color: var(--dark-card-bg);
    color: var(--dark-text-color);
}

.dark-mode .content {
    background-color: var(--dark-bg-color);
}

.dark-mode .main-content-wrapper {
    background-color: var(--dark-bg-color);
}

.dark-mode .navbar {
    background-color: var(--dark-bg-color);
}

.dark-mode .navbar-light .navbar-nav .nav-link {
    color: var(--dark-text-color);
}

.dark-mode .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Dark mode toggle - positioned relative to viewport */
.dark-mode-toggle {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.dark-mode-toggle .switch {
    margin-left: 10px;
}
