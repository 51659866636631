.announcements-modal {

    .modal-content {
        height: 700px;

        .carousel {
            height: 100%;
            overflow: hidden;

            .carousel-control-next, .carousel-control-prev{
                width: 10%;
                filter: invert(100%);
            }
            .carousel-indicators{
                filter: invert(100%);
            }

            .carousel-inner {
                height: 100%;

                .carousel-item {
                    height: 100%;
                }
            }
        }

        .announcement {
            height: 580px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding-top: 10px;

            .title {
                text-align: center;
            }

            .text {
                max-height: 150px;
                overflow: scroll;
                padding: 0 30px;
            }

            .image-wrapper {
                position: absolute;
                bottom: 0;
                display: flex;
                justify-content: center;

                & img {
                    max-width: 600px;
                    max-height: 350px;
                }
            }

            .hover:hover {
                cursor: pointer;
            }
        }
    }
}

.dark-mode .announcements-modal {
    .carousel{
        .carousel-control-next-icon, .carousel-control-prev-icon, .carousel-indicators{
            filter: inherit;
        }
    }

    .announcement {
        .title {
            color: var(--dark-primary-color)
        }

        .text {
            color: var(--dark-text-color)
        }
    }
}

.mobile-view .announcements-modal {
    .modal-content {
        height: 80vh;

        .carousel {
            height: 100%;

            .carousel-inner {
                height: 100%;

                .carousel-item {
                    height: 100%;
                }
            }
        }

        .announcement {
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding-top: 10px;

            .text {
                padding: 0 30px;
                overflow: scroll;
                max-height: 30%;
            }

            & img {
                max-width: 80%;
                max-height: 35vh;
            }
        }
    }
}